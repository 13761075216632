document.addEventListener("click", (e) => {
	const target = e.target;

	if (!(target instanceof HTMLAnchorElement)) {
		return;
	}

	const href = target.getAttribute("href");
	if (!href) {
		return;
	}

	const anyWindow = window as any;

	if (href.startsWith("#klav-form-")) {
		e.preventDefault();
		anyWindow._klOnsite = anyWindow._klOnsite || [];
		anyWindow._klOnsite.push(["openForm", href.replace("#klav-form-", "")]);
	}
});
